import React from "react";

const BeforeAfterIcon: React.FC = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" fill="#60A5FA" />
      <path
        d="M11 9C11 7.89543 11.8954 7 13 7H54C55.1046 7 56 7.89543 56 9V61C56 62.1046 55.1046 63 54 63H13C11.8954 63 11 62.1046 11 61V9Z"
        fill="white"
      />
      <g clipPath="url(#clip0_448_12246)">
        <rect x="13" y="5" width="45" height="56" rx="2" fill="white" />
        <rect width="22" height="44" transform="translate(13 5)" fill="white" />
        <path
          d="M19.917 22.7503C19.917 22.0139 20.5139 21.417 21.2503 21.417H29.2503C29.9867 21.417 30.5837 22.0139 30.5837 22.7503V30.7503C30.5837 31.4867 29.9867 32.0837 29.2503 32.0837H21.2503C20.5139 32.0837 19.917 31.4867 19.917 30.7503V22.7503Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.417 22.7503C19.417 21.7378 20.2378 20.917 21.2503 20.917H29.2503C30.2628 20.917 31.0837 21.7378 31.0837 22.7503V28.0832C31.0837 28.0835 31.0837 28.0838 31.0837 28.0841V30.7503C31.0837 31.7628 30.2628 32.5837 29.2503 32.5837H21.2503C20.2378 32.5837 19.417 31.7628 19.417 30.7503V29.4171C19.417 29.417 19.417 29.417 19.417 29.4169V22.7503ZM20.417 29.6241V30.7503C20.417 31.2106 20.7901 31.5837 21.2503 31.5837H29.2503C29.7106 31.5837 30.0837 31.2106 30.0837 30.7503V28.2908L29.1729 27.38C28.8475 27.0546 28.3198 27.0546 27.9944 27.38L27.2908 28.0837L28.2705 29.0634C28.4658 29.2587 28.4658 29.5753 28.2705 29.7705C28.0753 29.9658 27.7587 29.9658 27.5634 29.7705L26.2305 28.4376C26.2304 28.4375 26.2302 28.4374 26.2301 28.4372C26.23 28.4371 26.2298 28.4369 26.2297 28.4368L24.5062 26.7134C24.1808 26.3879 23.6532 26.3879 23.3277 26.7134L20.417 29.6241ZM26.5837 27.3766L25.2134 26.0062C24.4974 25.2903 23.3366 25.2903 22.6206 26.0062L20.417 28.2099V22.7503C20.417 22.2901 20.7901 21.917 21.2503 21.917H29.2503C29.7106 21.917 30.0837 22.2901 30.0837 22.7503V26.8766L29.88 26.6729C29.1641 25.957 28.0033 25.957 27.2873 26.6729L26.5837 27.3766ZM26.0837 24.0837C26.0837 23.8075 26.3075 23.5837 26.5837 23.5837H26.5903C26.8665 23.5837 27.0903 23.8075 27.0903 24.0837C27.0903 24.3598 26.8665 24.5837 26.5903 24.5837H26.5837C26.3075 24.5837 26.0837 24.3598 26.0837 24.0837Z"
          fill="#2563EB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.5 49V5H36.5V49H35.5Z"
          fill="#0D276A"
        />
        <rect width="21" height="44" transform="translate(37 5)" fill="white" />
        <path
          d="M42.917 22.7503C42.917 22.0139 43.5139 21.417 44.2503 21.417H52.2503C52.9867 21.417 53.5837 22.0139 53.5837 22.7503V30.7503C53.5837 31.4867 52.9867 32.0837 52.2503 32.0837H44.2503C43.5139 32.0837 42.917 31.4867 42.917 30.7503V22.7503Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.417 22.7503C42.417 21.7378 43.2378 20.917 44.2503 20.917H52.2503C53.2628 20.917 54.0837 21.7378 54.0837 22.7503V28.0832C54.0837 28.0835 54.0837 28.0838 54.0837 28.0841V30.7503C54.0837 31.7628 53.2628 32.5837 52.2503 32.5837H44.2503C43.2378 32.5837 42.417 31.7628 42.417 30.7503V29.4171C42.417 29.417 42.417 29.417 42.417 29.4169V22.7503ZM43.417 29.6241V30.7503C43.417 31.2106 43.7901 31.5837 44.2503 31.5837H52.2503C52.7106 31.5837 53.0837 31.2106 53.0837 30.7503V28.2908L52.1729 27.38C51.8475 27.0546 51.3198 27.0546 50.9944 27.38L50.2908 28.0837L51.2705 29.0634C51.4658 29.2587 51.4658 29.5753 51.2705 29.7705C51.0753 29.9658 50.7587 29.9658 50.5634 29.7705L49.2305 28.4376C49.2304 28.4375 49.2302 28.4374 49.2301 28.4372C49.23 28.4371 49.2298 28.4369 49.2297 28.4368L47.5062 26.7134C47.1808 26.3879 46.6532 26.3879 46.3277 26.7134L43.417 29.6241ZM49.5837 27.3766L48.2134 26.0062C47.4974 25.2903 46.3366 25.2903 45.6206 26.0062L43.417 28.2099V22.7503C43.417 22.2901 43.7901 21.917 44.2503 21.917H52.2503C52.7106 21.917 53.0837 22.2901 53.0837 22.7503V26.8766L52.88 26.6729C52.1641 25.957 51.0033 25.957 50.2873 26.6729L49.5837 27.3766ZM49.0837 24.0837C49.0837 23.8075 49.3075 23.5837 49.5837 23.5837H49.5903C49.8665 23.5837 50.0903 23.8075 50.0903 24.0837C50.0903 24.3598 49.8665 24.5837 49.5903 24.5837H49.5837C49.3075 24.5837 49.0837 24.3598 49.0837 24.0837Z"
          fill="#2563EB"
        />
        <g clipPath="url(#clip1_448_12246)">
          <rect x="13" y="48.5" width="45" height="12.5" fill="white" />
          <path
            d="M15.5 53C15.5 52.1716 16.1716 51.5 17 51.5H54C54.8284 51.5 55.5 52.1716 55.5 53C55.5 53.8284 54.8284 54.5 54 54.5H17C16.1716 54.5 15.5 53.8284 15.5 53Z"
            fill="#1E3A8A"
          />
          <path
            d="M15.5 57C15.5 56.4477 15.9477 56 16.5 56H36.5C37.0523 56 37.5 56.4477 37.5 57V57C37.5 57.5523 37.0523 58 36.5 58H16.5C15.9477 58 15.5 57.5523 15.5 57V57Z"
            fill="#3B82F6"
          />
        </g>
        <rect x="13.5" y="49" width="44" height="11.5" stroke="#1E3A8A" />
      </g>
      <rect x="13.5" y="5.5" width="44" height="55" rx="1.5" stroke="#1E3A8A" />
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
      <defs>
        <clipPath id="clip0_448_12246">
          <rect x="13" y="5" width="45" height="56" rx="2" fill="white" />
        </clipPath>
        <clipPath id="clip1_448_12246">
          <rect x="13" y="48.5" width="45" height="12.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BeforeAfterIcon;
