import { CalendarIcon, EyeIcon, ShareIcon } from "@heroicons/react/outline";
import {
  CheckIcon,
  InformationCircleIcon,
  XIcon,
} from "@heroicons/react/solid";

interface GenericBadgeProps {
  text: any;
  color: string;
  textSize?: string;
  textWeightNormal?: boolean;
  fillColor?: string;
  backgroundColorLevel?: string;
  roundedSize?: string;
  showDot?: boolean;
  showXIcon?: boolean;
  showLeadingShareIcon?: boolean;
  showTrailingShareIcon?: boolean;
  capitalizeText?: boolean;
  customStyle?: string;
  XIconOnClickFunc?: any;
  textOnClickFunc?: any;
  icon?: any;
  iconPlacement?: any;
  customIconStyle?: string;
  invertedColors?: boolean;
}

export default function GenericBadge({
  text,
  color,
  textSize = "xs",
  textWeightNormal,
  fillColor,
  backgroundColorLevel,
  roundedSize = "full",
  showDot = false,
  showXIcon = false,
  showLeadingShareIcon = false,
  showTrailingShareIcon = false,
  capitalizeText = false,
  customStyle = "",
  XIconOnClickFunc,
  textOnClickFunc,
  icon,
  iconPlacement,
  customIconStyle,
  invertedColors = false,
}: GenericBadgeProps) {
  let backgroundColor;
  if (!backgroundColorLevel) {
    backgroundColor = invertedColors ? `bg-${color}-500` : `bg-${color}-100`;
  } else {
    backgroundColor = `bg-${color}-${backgroundColorLevel}`;
  }

  const hoverXIconColor = `bg-${color}-200`;
  const textColor = invertedColors ? `text-white` : `text-${color}-800`;
  const leadingOrTrailingItemColor = `text-${color}-400`;
  const fontWeight = textWeightNormal
    ? `text-${textSize}`
    : `text-${textSize}-medium`;

  function getPadding() {
    if (showXIcon || showLeadingShareIcon || showTrailingShareIcon) {
      return "pl-2 pr-1";
    }
    return "";
  }

  const iconStyle = `flex flex-shrink-0 text-${color}-500`;
  function getIconStyle(customIconStyle: string = "") {
    return customIconStyle
      ? customIconStyle
      : `h-4 w-4 ${iconStyle} ${iconPlacement === "leading" ? "mr-1" : "ml-1"}`;
  }

  if (icon) {
    switch (icon) {
      case "information":
        icon = (
          <InformationCircleIcon className={getIconStyle(customIconStyle)} />
        );
        break;
      case "check":
        icon = <CheckIcon className={getIconStyle(customIconStyle)} />;
        break;
      case "calendar":
        icon = <CalendarIcon className={getIconStyle()} />;
        break;
      case "eye":
        icon = <EyeIcon className={getIconStyle(customIconStyle)} />;
        break;
    }
  }

  return (
    <span
      className={`inline-flex items-center rounded-${roundedSize} px-2.5 ${getPadding()} py-0.5 ${fontWeight} ${backgroundColor} ${textColor} ${customStyle} ${
        capitalizeText && "capitalize"
      }`}
    >
      {icon && iconPlacement === "leading" && <div>{icon}</div>}

      {showDot && (
        <svg
          className={`-ml-0.5 mr-1.5 h-2 w-2 ${leadingOrTrailingItemColor}`}
          fill={`${fillColor ? fillColor : "currentColor"}`}
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {showLeadingShareIcon && <ShareIcon className="w-4 h-4" />}
      <span
        onClick={textOnClickFunc}
        className={`${textOnClickFunc && "cursor-pointer"}`}
      >
        {text}
      </span>
      {showXIcon && (
        <button>
          <XIcon
            className={`w-4 h-4 ml-0.5 p-0.5 rounded-full hover:${hoverXIconColor} text-sm-semibold`}
            onClick={XIconOnClickFunc}
          />
        </button>
      )}

      {icon && iconPlacement === "lagging" && <div>{icon}</div>}

      {showTrailingShareIcon && <ShareIcon className="w-4 h-4" />}
    </span>
  );
}
