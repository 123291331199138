import React from "react";

const TeamSpotlightIcon: React.FC = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_66_245144)">
        <rect width="68" height="68" rx="2" fill="#DBEAFE" />
        <rect
          width="68"
          height="68"
          transform="translate(68) rotate(90)"
          fill="url(#paint0_linear_66_245144)"
        />
        <rect x="28.5" y="5" width="11" height="7" rx="1" fill="#0D276A" />
        <g clipPath="url(#clip1_66_245144)">
          <rect x="20.5" y="15" width="27" height="27" rx="13.5" fill="white" />
          <path
            d="M25.4013 35.7547C27.9408 34.3192 30.8747 33.5 34 33.5C37.1253 33.5 40.0592 34.3192 42.5987 35.7547M37.75 26C37.75 28.0711 36.0711 29.75 34 29.75C31.9289 29.75 30.25 28.0711 30.25 26C30.25 23.9289 31.9289 22.25 34 22.25C36.0711 22.25 37.75 23.9289 37.75 26ZM45.25 28.5C45.25 34.7132 40.2132 39.75 34 39.75C27.7868 39.75 22.75 34.7132 22.75 28.5C22.75 22.2868 27.7868 17.25 34 17.25C40.2132 17.25 45.25 22.2868 45.25 28.5Z"
            stroke="#3B82F6"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <rect x="15.5" y="47" width="37" height="4" rx="2" fill="#1E3A8A" />
        <rect x="9.5" y="53" width="49" height="2" rx="1" fill="#1E3A8A" />
        <rect x="26.5" y="59" width="15" height="4" rx="1" fill="#3B82F6" />
      </g>
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
      <defs>
        <linearGradient
          id="paint0_linear_66_245144"
          x1="-5.07162e-07"
          y1="30.0769"
          x2="64.4037"
          y2="33.5623"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2563EB" />
          <stop offset="0.317708" stopColor="#2563EB" stopOpacity="0.7" />
          <stop offset="0.838542" stopColor="#2563EB" stopOpacity="0.1" />
          <stop offset="1" stopColor="#2563EB" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_66_245144">
          <rect width="68" height="68" rx="2" fill="white" />
        </clipPath>
        <clipPath id="clip1_66_245144">
          <rect x="20.5" y="15" width="27" height="27" rx="13.5" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TeamSpotlightIcon;
