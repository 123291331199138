export default function ReviewIcon() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_5031_126847)">
        <rect width="68" height="68" rx="2" fill="white" />
        <path
          d="M8.09903 -0.928574C3.60256 2.29625 0.428589 7.58138 0.428589 13.404C0.428589 18.1516 3.2499 20.9286 6.51204 20.9286C9.59785 20.9286 11.8902 18.4204 11.8902 15.4643C11.8902 12.5082 9.86235 10.3583 7.21737 10.3583C6.68837 10.3583 5.98304 10.4479 5.80671 10.5375C6.24754 7.4918 9.06885 3.90866 11.8902 2.11709L8.09903 -0.928574ZM23.2636 -0.928574C18.8553 2.29625 15.6813 7.58138 15.6813 13.404C15.6813 18.1516 18.5026 20.9286 21.7648 20.9286C24.7624 20.9286 27.1429 18.4204 27.1429 15.4643C27.1429 12.5082 25.0269 10.3583 22.3819 10.3583C21.8529 10.3583 21.2358 10.4479 21.0594 10.5375C21.5003 7.4918 24.2334 3.90866 27.0547 2.11709L23.2636 -0.928574Z"
          fill="#BFDBFE"
        />
        <path
          d="M59.901 68.9286C64.3974 65.7037 67.5714 60.4186 67.5714 54.596C67.5714 49.8484 64.7501 47.0714 61.488 47.0714C58.4022 47.0714 56.1098 49.5796 56.1098 52.5357C56.1098 55.4918 58.1377 57.6417 60.7826 57.6417C61.3116 57.6417 62.017 57.5521 62.1933 57.4625C61.7525 60.5082 58.9311 64.0913 56.1098 65.8829L59.901 68.9286ZM44.7364 68.9286C49.1447 65.7037 52.3187 60.4186 52.3187 54.596C52.3187 49.8484 49.4974 47.0714 46.2352 47.0714C43.2376 47.0714 40.8571 49.5796 40.8571 52.5357C40.8571 55.4918 42.9731 57.6417 45.6181 57.6417C46.1471 57.6417 46.7642 57.5521 46.9406 57.4625C46.4997 60.5082 43.7666 64.0913 40.9453 65.8829L44.7364 68.9286Z"
          fill="#BFDBFE"
        />
        <rect
          x="13.0552"
          y="16.7642"
          width="44.4451"
          height="36.7358"
          rx="3.5"
          fill="#3B82F6"
        />
        <rect
          x="13.0552"
          y="16.7642"
          width="44.4451"
          height="36.7358"
          rx="3.5"
          stroke="#1E3A8A"
        />
        <rect
          x="10.5"
          y="14.5"
          width="44.4451"
          height="36.7358"
          rx="3.5"
          fill="white"
        />
        <rect
          x="15"
          y="19"
          width="16.4451"
          height="3"
          rx="1.5"
          fill="#2563EB"
        />
        <rect x="41.4451" y="19" width="9" height="3" rx="1.5" fill="#2563EB" />
        <rect x="15" y="28" width="35.4451" height="2" rx="1" fill="#1E3A8A" />
        <rect x="15" y="33" width="35.4451" height="2" rx="1" fill="#1E3A8A" />
        <rect x="15" y="38" width="22" height="2" rx="1" fill="#1E3A8A" />
        <rect
          x="10.5"
          y="14.5"
          width="44.4451"
          height="36.7358"
          rx="3.5"
          stroke="#1E3A8A"
        />
      </g>
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
      <defs>
        <clipPath id="clip0_5031_126847">
          <rect width="68" height="68" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
