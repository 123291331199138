export default function PhotoIcon() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" fill="white" />
      <rect width="37" height="68" fill="url(#paint0_linear_5031_126821)" />
      <rect x="5" y="5" width="11" height="7" rx="1" fill="#0D276A" />
      <rect x="5" y="51" width="26" height="1.5" rx="0.75" fill="#1E3A8A" />
      <rect x="5" y="54.5" width="22" height="1.5" rx="0.75" fill="#1E3A8A" />
      <rect x="5" y="59" width="16" height="4" rx="1" fill="white" />
      <path
        d="M24.6666 38.6667L30.0167 33.3166C30.9279 32.4054 32.4053 32.4054 33.3165 33.3166L38.6666 38.6667M36.3333 36.3333L38.1834 34.4833C39.0946 33.572 40.572 33.572 41.4832 34.4833L43.3333 36.3333M36.3333 29.3333H36.345M27 43.3333H41C42.2886 43.3333 43.3333 42.2887 43.3333 41V27C43.3333 25.7113 42.2886 24.6667 41 24.6667H27C25.7113 24.6667 24.6666 25.7113 24.6666 27V41C24.6666 42.2887 25.7113 43.3333 27 43.3333Z"
        stroke="#1E3A8A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
      <defs>
        <linearGradient
          id="paint0_linear_5031_126821"
          x1="-2.75956e-07"
          y1="30.0769"
          x2="35.1154"
          y2="31.111"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2563EB" />
          <stop offset="0.317708" stopColor="#2563EB" stopOpacity="0.7" />
          <stop offset="0.838542" stopColor="#2563EB" stopOpacity="0.1" />
          <stop offset="1" stopColor="#2563EB" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
