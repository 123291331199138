import React from "react";

const BlogIcon: React.FC = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_65_244921)">
        <rect width="68" height="68" rx="2" fill="#1E3A8A" />
        <path
          d="M58.524 -1.20868C57.8508 1.40458 56.1033 3.74961 53.5733 5.43483C51.0432 7.12004 47.8839 8.04344 44.6227 8.05082C41.3615 8.0582 38.1959 7.14913 35.6545 5.47539C33.1132 3.80166 31.3498 1.46458 30.659 -1.14559L36.7455 -2.22604C37.1344 -0.756821 38.127 0.558679 39.5574 1.50079C40.9879 2.44291 42.7698 2.95461 44.6055 2.95046C46.4411 2.9463 48.2195 2.42654 49.6436 1.47796C51.0677 0.529382 52.0514 -0.790592 52.4303 -2.26155L58.524 -1.20868Z"
          fill="#60A5FA"
        />
        <path
          d="M58.8656 49.9734C57.2766 51.3516 55.4082 52.3698 53.3886 52.9581C51.3691 53.5464 49.2462 53.6909 47.1656 53.3817C45.0849 53.0725 43.0958 52.317 41.3346 51.1669C39.5734 50.0167 38.0818 48.4993 36.9621 46.7186L42.3593 43.3249C42.9896 44.3272 43.8292 45.1814 44.8205 45.8288C45.8119 46.4762 46.9315 46.9015 48.1027 47.0755C49.2738 47.2495 50.4688 47.1682 51.6055 46.837C52.7423 46.5059 53.794 45.9328 54.6884 45.157L58.8656 49.9734Z"
          fill="#60A5FA"
        />
        <path
          d="M46.4456 39.9508C47.1282 37.9612 48.2331 36.1427 49.6845 34.6202C51.1359 33.0977 52.8995 31.9072 54.8542 31.1302C56.8089 30.3533 58.9086 30.0083 61.0092 30.119C63.1097 30.2298 65.1615 30.7935 67.0237 31.7717L64.0591 37.4159C63.0108 36.8653 61.8559 36.548 60.6736 36.4857C59.4912 36.4234 58.3093 36.6175 57.2091 37.0548C56.1088 37.4922 55.1161 38.1623 54.2991 39.0193C53.4822 39.8763 52.8602 40.8999 52.476 42.0198L46.4456 39.9508Z"
          fill="#60A5FA"
        />
        <path
          d="M72.9452 37.542C74.2159 39.8183 74.8533 42.3936 74.791 44.9998C74.7288 47.6061 73.9691 50.148 72.5911 52.361C71.2131 54.574 69.2671 56.3772 66.9558 57.5829C64.6444 58.7887 62.0521 59.3529 59.4487 59.2168L59.7814 52.85C61.2469 52.9266 62.706 52.6091 64.007 51.9304C65.3081 51.2517 66.4034 50.2367 67.1791 48.991C67.9547 47.7453 68.3823 46.3145 68.4174 44.8476C68.4524 43.3806 68.0936 41.931 67.3784 40.6497L72.9452 37.542Z"
          fill="#60A5FA"
        />
        <path
          d="M63.8463 68.1696C61.7401 69.7059 59.2606 70.6496 56.6659 70.9024C54.0713 71.1553 51.4562 70.7081 49.093 69.6074C46.7298 68.5067 44.7048 66.7927 43.2288 64.6439C41.7528 62.495 40.8797 59.9898 40.7004 57.389L47.0608 56.9506C47.1617 58.4145 47.6532 59.8246 48.484 61.0342C49.3148 62.2438 50.4547 63.2085 51.7849 63.8281C53.1151 64.4477 54.587 64.6994 56.0475 64.557C57.508 64.4147 58.9037 63.8835 60.0892 63.0187L63.8463 68.1696Z"
          fill="#60A5FA"
        />
        <rect x="5" y="17" width="24" height="4" rx="2" fill="white" />
        <rect x="39" y="17" width="9" height="4" rx="2" fill="white" />
        <rect x="5" y="25" width="43" height="2" rx="1" fill="white" />
        <rect x="5" y="30" width="42" height="2" rx="1" fill="white" />
        <rect x="5" y="35" width="22" height="2" rx="1" fill="white" />
        <rect x="5" y="55" width="11" height="7" rx="1" fill="white" />
      </g>
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
      <defs>
        <clipPath id="clip0_65_244921">
          <rect width="68" height="68" rx="2" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlogIcon;
