export default function GeneralAnnouncementIcon() {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" fill="white" />
      <rect x="7" y="7" width="37" height="4" rx="2" fill="#1E3A8A" />
      <rect x="7" y="13" width="49" height="3" rx="1.5" fill="#1E3A8A" />
      <rect x="7" y="20" width="15" height="4" rx="1" fill="#3B82F6" />
      <rect x="7.5" y="29.5" width="53" height="31" rx="3.5" fill="#3B82F6" />
      <path
        d="M33.1667 39.9018V51.0335C33.1667 51.8434 32.5101 52.5 31.7001 52.5C31.0804 52.5 30.5275 52.1104 30.3191 51.5267L28.5304 46.4024M39 45.8333C40.3807 45.8333 41.5 44.714 41.5 43.3333C41.5 41.9526 40.3807 40.8333 39 40.8333M28.5304 46.4024C27.3369 45.8951 26.5 44.7119 26.5 43.3333C26.5 41.4924 27.9924 40 29.8333 40H31.3601C34.7773 40 37.7141 38.9716 39 37.5L39 49.1667C37.7141 47.6951 34.7773 46.6667 31.3601 46.6667L29.8333 46.6667C29.371 46.6667 28.9306 46.5725 28.5304 46.4024Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="7.5" y="29.5" width="53" height="31" rx="3.5" stroke="#1E3A8A" />
      <rect x="0.5" y="0.5" width="67" height="67" rx="1.5" stroke="#1E3A8A" />
    </svg>
  );
}
